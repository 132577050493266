<template>
    <div class="order">
        <div class="handle-box">
            <span>日期</span>
            <el-date-picker v-model="value2"
                            style="width: 240px"
                            :clearable="false"
                            :default-value="defaultDate"
                            @change="changeTimer"
                            format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD"
                            type="daterange"
                            align="right"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :shortcuts="shortcuts">
                <!-- unlink-panels -->
            </el-date-picker>
            <span>客户端类型:</span>
            <el-select v-model="clientId"
                       placeholder="请选择"
                       class="handle-select mr10 w80">
                <el-option key
                           label="全部"
                           value></el-option>
                <el-option v-for="(item,i) in channelList "
                           :key="i"
                           :label="item.description"
                           :value="item.clientId"></el-option>
            </el-select>
            <el-button type="primary ml10"
                       style="marginLeft: 10px"
                       icon="el-icon-search"
                       @click="searchData">搜索</el-button>
            <el-button type="primary ml10"
                       icon="el-icon-delete"
                       @click="clearData">清空</el-button>
        </div>
        <div class="table">
            <el-table ref="multipleTable"
                      :data="tableData"
                      tooltip-effect="dark"
                      style="width: 100%"
                      @selection-change="handleSelectionChange">
                <el-table-column type="index"
                                 label="序号"
                                 width="120"
                                 align="center">
                </el-table-column>
                <el-table-column prop="installnum"
                                 align="center"
                                 label="启动安装"></el-table-column>
                <el-table-column prop="doneNum"
                                 align="center"
                                 label="完成安装"></el-table-column>
                <el-table-column prop="startNum"
                                 align="center"
                                 label="启动UV"></el-table-column>

                <el-table-column prop="createTime"
                                 align="center"
                                 label="创建时间">
                    <template #default="scope">
                        <span>{{formatDate (scope.row.time)}}</span>
                    </template>
                </el-table-column>

                <!-- <el-table-column prop="id"
                         label="支付状态">
        </el-table-column>-->
                <el-table-column prop="clientId"
                                 align="center"
                                 label="客户端类型">
                    <template #default="scope">
                        <span> {{ showClient(scope.row.clientId) }}</span>
                        <!-- channelList -->

                    </template>
                </el-table-column>
            </el-table>
            <div class="page">
                <el-pagination background
                               layout=" prev, pager, next"
                               :current-page="pageIndex"
                               :page-size="20"
                               :total="total"
                               @current-change="pageChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
// getDownExcel
import {
    getDayTimer,
    clientConfig,
    getChannelList,
    refund,
    getBaiDuCount
} from "../../api/index";
import axios from "axios";
var md5 = require('../../utils/md5.js')
export default {
    data () {
        return {
            name: "order",
            documentType: "",
            isEnable: "",
            categoryId: "",
            fatherList: [{ id: 1, name: "" }],
            SonId: "",
            sonList: [{ id: 1, name: "" }],
            value2: "",
            defaultDate: [],
            shortcuts: [
                {
                    text: "最近一周",
                    value: (() => {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        return [start, end];
                    })()
                },
                {
                    text: "最近一个月",
                    value: (() => {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        return [start, end];
                    })()
                },
                {
                    text: "最近三个月",
                    value: (() => {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        return [start, end];
                    })()
                }
            ],
            days: [],
            tableData: [],
            multipleSelection: [],
            orderStatus: [
                {
                    name: "未付款",
                    id: 0
                },
                {
                    name: "已付款",
                    id: 1
                },
                {
                    name: "已退款",
                    id: 2
                },
                {
                    name: "过期作废",
                    id: 3
                }
            ],
            payTypeList: [
                { name: "全部", id: "" },
                { name: "微信", id: 1 },
                { name: "支付宝", id: 2 }
            ],
            total: 0,
            pageIndex: 1,
            startTime: "",
            endTime: "",
            orderId: "",
            status: "",
            payType: "",
            tradeNo: "",
            userId: "",
            nickName: "",
            clientId: "",
            channelId: "", // 渠道id
            orderTypeId: "", //订单类型
            channelList: "", //渠道列表
            channelDatas: [] // 渠道下拉
        };
    },
    async mounted () {
        this.channelArray();

        await this.queryClientConfig();
        this.getData();
        this.getDays();
    },
    methods: {

        formatDate (oldDate) {
            // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
            function add0 (num) { return num < 10 ? '0' + num : num } // 个位数的值在前面补0
            const date = new Date(oldDate)
            const Y = date.getFullYear()
            const M = date.getMonth() + 1
            const D = date.getDate()
            const h = date.getHours()
            const m = date.getMinutes()
            const s = date.getSeconds()

            const dateString = Y + '-' + add0(M) + '-' + add0(D) + '  ' + add0(h) + ':' + add0(m) + ':' + add0(s)
            return dateString.slice(0, 10)

            // 方式2 转换为'yyyy/MM/dd HH:mm:ss'
            // return new Date(oldDate).toLocaleString()
        },

        showClient (v) {
            if (this.channelList.length > 0) {
                let data = this.channelList.filter(item => {
                    return item.clientId == v;
                })
                if (data.length > 0) {
                    return data[0].description;
                } else {
                    return '未知';
                }

            } else {
                return '未知';
            }

        },
        getCount () {
            let params = {

                startTime: this.startTime.slice(0, 10),
                endTime: this.endTime.slice(0, 10),
                clientId: this.clientId,//空则查询全部
                pageIndex: 1,
                pageSize: 20
            };
            getBaiDuCount(params).then(res => {
                if (res.code == 0) {
                    console.log(res);
                    this.tableData = res.data.data;
                    this.total = res.data.total; // 总数
                    console.log(this.tableData, "格式");

                } else {
                    this.$message(res.message);
                }
            })

        },
        refundFn (index, row) {  // 退款
            this.$confirm('是否确认退款?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let key = '5e776136251349edafab59af837c031e';
                let amount = row.amount
                let lenovoToken = 'lenovoToken'
                let orderId = row.orderId
                let userId = row.userId
                let userName = 'admin'
                let str = `${key}${amount}${lenovoToken}${orderId}${userId}${userName}`
                console.log('sign', str)
                let sign = md5(str).toUpperCase()
                console.log("row", row);
                refund({ amount, lenovoToken, orderId, userName, sign, userId }).then(res => {
                    console.log(99, res);
                    if (res.code == 0) {
                        this.$message.success("退款发起成功");
                        // row.status = 2
                    } else {
                        return this.$message.error(res.message);
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        channelArray () {
            getChannelList().then(res => {
                console.log(99, res);
                if (res.code == 0) {
                    this.channelDatas = res.data.filter(item => {
                        return item != "";
                    });
                    this.channelDatas.unshift("全部");
                }
            });
        },
        queryClientConfig () {
            clientConfig().then(res => {
                if (res.code == 0) {
                    this.channelList = res.data;
                }
                console.log("res", res);
            });
        },
        // 导出功能
        downFile () {
            let params = {
                startTime: this.startTime,
                endTime: this.endTime,
                orderId: this.orderId,
                status: this.status,
                payType: this.payType,
                tradeNo: this.tradeNo,
                userId: this.userId,
                nickName: this.nickName,
                clientId: this.clientId,
                pageIndex: this.pageIndex, //必需
                pageSize: 20, //必需
                orderTypeId: this.orderTypeId, //订单类型
                channel: this.channelId == "全部" ? "" : this.channelId
            };
            let data = {};
            let url =
                process.env.VUE_APP_BASE_MIAOHUI + "/miaohui/admin/order/export";
            // excel 表格下载
            axios({
                method: "GET",
                headers: {
                    "Content-Type": "application/json; application/octet-stream"
                },
                url: url,
                params: params,
                data: data,
                responseType: "arraybuffer"
            })
                .then(res => {
                    let b = new Blob([res.data], { type: "application/vnd.ms-excel" });
                    let url = URL.createObjectURL(b);
                    let link = document.createElement("a");
                    link.download =
                        "订单数据" +
                        new Date(+new Date() + 8 * 3600 * 1000)
                            .toJSON()
                            .substr(0, 19)
                            .replace("T", " ") +
                        ".xls";
                    link.href = url;
                    link.click();
                })
                .catch(err => {
                    console.log(err);
                });
        },
        // 获取时间
        getDays () {
            getDayTimer().then(res => {
                if (res.code == 0) {
                    this.days = res.data;
                }
            });
        },

        changeTimer (v) {
            //  格式化时间
            const d1 = new Date(v[0]);
            const d2 = new Date(v[1]);
            const resDate =
                d1.getFullYear() +
                "-" +
                this.p(d1.getMonth() + 1) +
                "-" +
                this.p(d1.getDate());
            const eDate =
                d2.getFullYear() +
                "-" +
                this.p(d2.getMonth() + 1) +
                "-" +
                this.p(d2.getDate());
            this.startTime = resDate + " 00:00:00";
            this.endTime = eDate + " 23:59:59";
            console.log(resDate, eDate);
        },
        p (s) {
            return s < 10 ? "0" + s : s;
        },

        searchData () {
            this.pageIndex = 1;
            this.getData();
        },
        clearData () {
            this.value2 = "";
            this.pageIndex = 1;
            this.startTime = "";
            this.endTime = "";
            this.orderId = "";
            this.status = "";
            this.payType = "";
            this.tradeNo = "";
            this.userId = "";
            this.nickName = "";
            this.clientId = "";
            this.orderTypeId = ""; //订单类型
            this.channelId = "";
            this.getData();
        },
        getData () {
            this.getCount();
            // let params = {
            //     startTime: this.startTime,
            //     endTime: this.endTime,
            //     orderId: this.orderId,
            //     status: this.status,
            //     payType: this.payType,
            //     tradeNo: this.tradeNo,
            //     userId: this.userId,
            //     nickName: this.nickName,
            //     clientId: this.clientId,
            //     pageIndex: this.pageIndex, //必需
            //     pageSize: 20, //必需
            //     orderTypeId: this.orderTypeId, //订单类型
            //     channel: this.channelId == "全部" ? "" : this.channelId
            // };
            // getOrderList(params).then(res => {
            //     console.log(res);
            //     this.tableData = res.data.data;
            //     this.total = res.data.total; // 总数
            //     console.log(this.tableData, "格式");
            // });
        },
        pageChange (v) {
            this.pageIndex = v;
            this.getData();
            console.log("当前页", v);
        },
        changeLabel () { },
        fatherChange () { },
        channelChange () { },
        resetList () { },
        toggleSelection (rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange (val) {
            this.multipleSelection = val;
        }
    }
};
</script>
<style lang="scss">
.order {
    .handle-box {
        padding-top: 10px;
        .w100 {
            width: 180px;
        }
    }
    .table {
        padding-top: 20px;
    }
    .page {
        .el-pagination {
            text-align: center;
            padding-top: 20px;
        }
    }
}
</style>